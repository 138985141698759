<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 공정 -->
          <c-process
            :editable="editable"
            label="공정"
            name="processCd"
            v-model="searchParam.processCd"
          >
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 작업기간 -->
          <c-datepicker
            :range="true"
            label="작업기간"
            defaultStart="-6M"
            defaultEnd="6M"
            name="period"
            v-model="searchParam.period"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 작성상태 -->
          <c-select
            :comboItems="statusItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="tbmCompleteFlag"
            label="작성상태"
            v-model="searchParam.tbmCompleteFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <!-- TBM 목록 -->
    <c-table
      ref="tasktable"
      title="TBM 목록"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      :columnSetting="false"
      :isFullScreen="false"
      :usePaging="false"
      :expandAll="true"
      selection="multiple"
      rowKey="tbmId"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="삭제" icon="remove" @btnClicked="deleteTask" />
          <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addTask" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'constructionRelactionTaskTbm',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopConstructionId: '',
      }),
    },
    task: {
      type: Object,
      default: () => ({
        insertUrl: '',
        deleteUrl: '',
      }),
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            style: 'width:140px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'tbmNo',
            field: 'tbmNo',
            // TBM번호
            label: 'TBM번호',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'tbmName',
            field: 'tbmName',
            // TBM명
            label: 'TBM명',
            align: 'left',
            style: 'width:200px',
            type: 'link',
            sortable: true,
          },
          {
            name: 'permitNo',
            field: 'permitNo',
            // 작업허가번호
            label: '작업허가번호',
            style: 'width:100px',
            align: 'center',
            type: 'link',
            sortable: true,
          },
          {
            name: 'tbmWorkDate',
            field: 'tbmWorkDate',
            // 작업일
            label: '작업일',
            style: 'width:80px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'tbmCompleteFlagName',
            field: 'tbmCompleteFlagName',
            label: '작성상태',
            style: 'width:80px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'processName',
            field: 'processName',
            style: 'width:80px',
            // 공정
            label: '공정',
            align: 'center',
            sortable: true,
          },
          {
            name: 'vendorName',
            field: 'vendorName',
            style: 'width:80px',
            // 업체
            label: '업체',
            align: 'center',
            sortable: true,
          },
          {
            name: 'sopName',
            field: 'sopName',
            style: 'width:120px',
            // 작업
            label: '작업',
            align: 'center',
            sortable: true,
          },
          {
            name: 'tbmWorkResponsibleName',
            field: 'tbmWorkResponsibleName',
            // 작업책임자
            label: '작업책임자',
            style: 'width:80px',
            align: 'center',
            sortable: true,
          },
          // {
          //   name: 'workUserCount',
          //   field: 'workUserCount',
          //   label: '외',
          //   style: 'width:40px',
          //   align: 'center',
          //   sortable: true,
          // },
          {
            name: 'permitTypeName',
            field: 'permitTypeName',
            // 허가서구분
            label: '허가서구분',
            style: 'width:80px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'workTime',
            field: 'workTime',
            // 작업시간
            label: '작업시간',
            style: 'width:80px',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        startYmd: '',
        endYmd: '',
        period: [],
        plantCd: '',
        processCd: '',
        tbmCompleteFlag: null,
        sopConstructionId: '',
        constructionFlag: true,
      },
      statusItems: [
        { code: 'N', codeName: '작성중' },
        { code: 'Y', codeName: '작성완료' },
      ],
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: false,
        width: '80%',
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.tbm.list.url;
      // code setting
      this.$set(this.searchParam, 'sopConstructionId', this.popupParam.sopConstructionId)
      // list setting
      this.getList();
    },
    getList() {
      if (this.searchParam.period) {
        this.searchParam.startYmd = this.searchParam.period[0];
        this.searchParam.endYmd = this.searchParam.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row, col) {
      this.detailPopup(row, col);
    },
    detailPopup(result, col) {
      if (col && col.name === 'permitNo') {
        this.popupOptions.target = () => import(`${"@/pages/sop/swp/safeWorkPermitDetail.vue"}`);
        this.popupOptions.title = '작업허가서 상세'; // 작업허가서 상세
        this.popupOptions.param = {
          sopWorkPermitId: result ? result.sopWorkPermitId : '',
          permitTypeCd: result ? result.permitTypeCd : '',
        };
      } else {
        this.popupOptions.target = () => import(`${"@/pages/sai/tbm/tbmDetail.vue"}`);
        this.popupOptions.title = 'TBM 상세'; // TBM 상세
        this.popupOptions.param = {
          tbmId: result ? result.tbmId : '',
        };
      }
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    deleteTask() {
      let selectData = this.$refs['tasktable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.', // 선택될 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.task.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(this.$_.map(selectData, (item) => {
                return {
                  sopConstructionId: this.popupParam.sopConstructionId,
                  tbmId: item.tbmId,
                }
              }))
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$refs['tasktable'].$refs['compo-table'].clearSelection();
              this.getList();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addTask() {
      this.popupOptions.title = 'TBM 검색'; // TBM 검색
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/sai/tbm/tbmPop.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeTaskPopup;
    },
    closeTaskPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let insertList = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.grid.data, { tbmId: item.tbmId }) === -1) {
            insertList.push({
              sopConstructionId: this.popupParam.sopConstructionId,
              tbmId: item.tbmId,
            });
          }
        })

        this.$http.url = this.task.insertUrl;
        this.$http.type = 'POST';
        this.$http.param = insertList
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getList();
        },);
      }
    },
  }
};
</script>
